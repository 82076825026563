import React, { useContext } from "react"
import Template from "../components/template"
import ContactForm from "../components/contact-form"
import "../styles/barrierefreiheit.css"
import { barrierefreiheitContent } from "../content/barrierefreiheit"
import { SettingsContext } from "../contexts/settings"
import { graphql } from "gatsby"
import { translate } from "../../utils/language" 
import {BlogpostDownload} from "../components/BlogpostDownload"
import {BlogpostBildMitText} from "../components/BlogpostBildMitText"
import {BlogpostText} from "../components/BlogpostText"
import {BlogpostGallerie} from "../components/BlogpostGallerie"
import {BlogpostVideo} from "../components/BlogpostVideo"
import {BlogpostExternerInhalt} from "../components/BlogpostExternerInhalt"
import {BlogpostExternerInhaltMitText} from "../components/BlogpostExternerInhaltMitText"
import {BarrierefreiheitBildMitVarianteUndTextblock} from "../components/BarrierefreiheitBildMitVarianteUndTextblock"
import {BarrierefreiheitKarte} from "../components/BarrierefreiheitKarte"

const BarrierefreiheitPage = ({data}) => {
    const [settings, ] = useContext(SettingsContext)
    const content = barrierefreiheitContent[settings.language]
    const l = settings.language
    const b = data.strapi.barrierefreiheit
    return (
        <Template currentPage="accessibility">
            <section className="end-of-site">
                <div>
                <h1>{translate(l, b.Titel_deutsch, b.Titel_englisch)}</h1>
                        {
                            b.Inhalt.map(blogComponent => {
                                switch (blogComponent.__typename) {
                                    case "Strapi_ComponentDefaultMehrsprachigerDownload":
                                        return <BlogpostDownload data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultBildMitMehrsprachigemText":
                                        return <BlogpostBildMitText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultMehrsprachigerText":
                                        return <BlogpostText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultGallerie":
                                        return <BlogpostGallerie data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultVideo":
                                        return <BlogpostVideo data={blogComponent} />;
                                    case "Strapi_ComponentDefaultExternerInhalt":
                                        return <BlogpostExternerInhalt data={blogComponent} />;
                                    case "Strapi_ComponentDefaultExternerInhaltMitText":
                                        return <BlogpostExternerInhaltMitText data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultTrennstrich":
                                        return <div className="mint-line-blogpost" data={blogComponent}/>;
                                    case "Strapi_ComponentDefaultBildMitVarianteUndTextblock":
                                        return <BarrierefreiheitBildMitVarianteUndTextblock data={blogComponent} language={l} />;
                                    case "Strapi_ComponentDefaultKarte":
                                        return <BarrierefreiheitKarte data={blogComponent} language={l} />;
                                }
                            }


                            )
                        }
                    <ContactForm className="end-of-site" apiSuffix="barrierefreiheit" mailto="mailto:access@nocturnal-unrest.de" email="access@nocturnal-unrest.de" titel={content.titelContactForm}></ContactForm> 
                    
                </div>
            </section>
        </Template>
    )
}

export default BarrierefreiheitPage

export const query = graphql`
    query MyQuery5 {
        strapi {
            barrierefreiheit {
                Titel_deutsch
                Titel_englisch
                Inhalt {
                    __typename  
                ... on Strapi_ComponentDefaultKarte {
                    ExternalLink
                    EmbedURL
                }
                ... on Strapi_ComponentDefaultBildMitVarianteUndTextblock {
                    Alt_de
                    Alt_en
                    Bild_dunkel {
                        url
                    }
                    Bild_hell {
                        url
                    }
                    Deutsch
                    Englisch
                    Titel_de
                    Titel_en
                }
                ... on Strapi_ComponentDefaultMehrsprachigerDownload {
                    Titel_englisch
                    Titel_deutsch
                    Link_deutsch
                    Link_englisch
                    Download_deutsch {
                    url
                    }
                    Download_englisch {
                    url
                    }
                }
                ... on Strapi_ComponentDefaultBildMitMehrsprachigemText {
                    Deutsch
                    Englisch
                    Titel_deutsch
                    Titel_englisch
                    bild {
                        Deutsch
                        Englisch
                        Untertitel_de
                        Untertitel_en
                        Bild {
                        url
                        }
                    }
                }
                ... on Strapi_ComponentDefaultVideo {
                    VideoDatei {
                    url
                    }
                }
                ... on Strapi_ComponentDefaultMehrsprachigerText {
                    Deutsch
                    Englisch
                }
                ... on Strapi_ComponentDefaultGallerie {
                    Bilder {
                    Bild {
                        url
                    }
                    Deutsch
                    Englisch
                    Untertitel_de
                    Untertitel_en
                    }
                }
                ... on Strapi_ComponentDefaultExternerInhalt {
                    URL
                }
                ... on Strapi_ComponentDefaultTrennstrich {
                    id
                }
                ... on Strapi_ComponentDefaultExternerInhaltMitText {
                    Deutsch
                    Englisch
                    URL
                }
            }
            }
        }
    }
`