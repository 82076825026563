const barrierefreiheitContent = {
    german: {
        mapTitelMousonturm: "Interaktiver Ausschnitt einer Open Maps Karte, die zeigt, wie der Veranstaltungsort zu finden ist.",
        titelContactForm: "Kontakt zum Barrierefreiheits-Team",

    },
    english: {
        mapTitelMousonturm: "Interactive section of an Open Maps map showing where to find the event space.",
        titelContactForm: "Contact the accessibility team",
    }
}
export {barrierefreiheitContent}