import React, { useContext, useState, useCallback } from 'react';
import { Link } from 'gatsby';
import '../styles/contact-form.css';
import { contactFormContent } from '../content/contact-form';
import { SettingsContext } from '../contexts/settings';

const ContactForm = ({ titel, mailto, email, phone }) => {
  const [settings] = useContext(SettingsContext);
  const content = contactFormContent[settings.language];

  return (
    <div>
      <div className="black-box-contact-form">
        <div className="contact-details">
          <h2 className="headline-contact">{titel}</h2>
          <div className="text-contact">
            <p className="phone-contact">{phone}</p>
            <p className="e-mail-contact">
              E-Mail:{' '}
              <a className="link" href={mailto}>
                {email}
              </a>
            </p>
          </div>
          <p className="link-to-team">
            {content.first}
            <Link className="link-contact-form" to="/team">
              {content.firstLink}
            </Link>
          </p>
        </div>
      </div>
      <div className="mint-line-contact-form end-of-site"></div>
    </div>
  );
};
export default ContactForm;
