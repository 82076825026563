const contactFormContent = {
    german: {
        headlineFirst: "Kontakt zum Awareness-Team",
        headlineSecond: "Kontakt zum Technischen Support",
        first: "Erfahre mehr über das Team ",
        firstLink: "hier.",
        name: "Name",
        email: "E-Mail Adresse",
        subject: "Betreff",
        message: "Nachricht",
        messageWrite: "Schreib etwas",
        button: "Senden"
    },
    english: {
        headlineFirst: "Contact the awareness team",
        headlineSecond: "Contact the technical support",
        first: "Find out more about the team ",
        firstLink: "here.",
        name: "Name",
        email: "E-Mail Adress",
        subject: "Subject",
        message: "Message",
        messageWrite: "Write something",
        button: "Submit"

    }
}
export {contactFormContent}