import React, { useContext } from "react"
import { barrierefreiheitContent } from "../content/barrierefreiheit"
import { SettingsContext } from "../contexts/settings"

export const BarrierefreiheitKarte = ({data}) => {
    const [settings, ] = useContext(SettingsContext)
    const content = barrierefreiheitContent[settings.language]
    return <div className="externer-inhalt-container">
        <div className="map-container">
            <iframe titel={content.mapTitelMousonturm} className="map" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={data.EmbedURL}></iframe><br/>
            <small><a className="link" href={data.ExternalLink} target="_blank" rel="noopener noreferrer">{content.map}</a></small>
        </div>
    </div>
    }