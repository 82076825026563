import React, { useContext } from "react"
import { translate } from "../../utils/language"
import { imageUrl } from "../../utils/path"
import { renderMarkdown } from "../../utils/rendermarkdown"
import { SettingsContext } from "../contexts/settings"

export const BarrierefreiheitBildMitVarianteUndTextblock = ({data, language}) => {
    const [settings, ] = useContext(SettingsContext)
    return <div>
        <h2>{translate(language, data.Titel_de, data.Titel_en)}</h2>
        <div className="pic-text-barrierefreiheit">
            <img className="pic-barrierefreiheit-container" src={settings.isHighContrast ? imageUrl(data.Bild_hell.url) : imageUrl(data.Bild_dunkel.url)} alt={translate(language, data.Alt_de, data.Alt_en)}/>
            <p className="text-b">{renderMarkdown(translate(language, data.Deutsch, data.Englisch))}</p>
        </div>
    </div>
    }